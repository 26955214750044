import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Configuring server timeouts";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Warning = makeShortcode("Warning");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "configuring-server-timeouts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#configuring-server-timeouts",
        "aria-label": "configuring server timeouts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Configuring server timeouts`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#using-typeserverbuilder"
          }}>{`Using type://ServerBuilder`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#using-typevirtualhostbuilder"
          }}>{`Using type://VirtualHostBuilder`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#using-typeservicebindingbuilder"
          }}>{`Using type://ServiceBindingBuilder`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#using-typeservicerequestcontext"
          }}>{`Using type://ServiceRequestContext`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#using-typerequesttimeout-annotation"
          }}>{`Using type://@RequestTimeout Annotation`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#global-configuration"
          }}>{`Global Configuration`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#using-typeflagsprovider"
            }}>{`Using type://FlagsProvider`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#using-jvm-system-properties"
            }}>{`Using JVM system properties`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`There are several ways to override the default timeouts at server-side.`}</p>
    <h2 {...{
      "id": "using-typeserverbuilder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-typeserverbuilder",
        "aria-label": "using typeserverbuilder permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<a parentName="h2" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a></h2>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.ServerBuilder;

import java.time.Duration;

ServerBuilder sb = Server.builder();
Server server = sb.http(port)
                  .annotatedService(new HelloService())
                  .requestTimeout(Duration.ofSeconds(5))
                  .build();
`}</code></pre>
    <h2 {...{
      "id": "using-typevirtualhostbuilder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-typevirtualhostbuilder",
        "aria-label": "using typevirtualhostbuilder permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<a parentName="h2" {...{
        "href": "type://VirtualHostBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/VirtualHostBuilder.html"
      }}>{`type://VirtualHostBuilder`}</a></h2>
    <p>{`You can set the timeouts for each virtual host.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.ServerBuilder;

import java.time.Duration;

import static com.linecorp.armeria.common.HttpStatus.OK;

ServerBuilder sb = Server.builder();
Server server = sb.http(port)
                  .defaultVirtualHost()
                  .service("/hello1", (ctx, req) -> HttpResponse.of(OK))
                  .requestTimeout(Duration.ofSeconds(5))
                  .and()
                  .virtualHost("*.foo.com")
                  .service("/hello2", (ctx, req) -> HttpResponse.of(OK))
                  .requestTimeout(Duration.ofSeconds(10))
                  .and()
                  .withVirtualHost(builder -> {
                      builder.hostnamePattern("*.bar.com")
                             .service("/hello3", (ctx, req) -> HttpResponse.of(OK))
                             .requestTimeout(Duration.ofSeconds(15));
                  })
                  .build();
`}</code></pre>
    <h2 {...{
      "id": "using-typeservicebindingbuilder",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-typeservicebindingbuilder",
        "aria-label": "using typeservicebindingbuilder permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<a parentName="h2" {...{
        "href": "type://ServiceBindingBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceBindingBuilder.html"
      }}>{`type://ServiceBindingBuilder`}</a></h2>
    <p>{`You can set the timeouts for each route.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.ServerBuilder;

import java.time.Duration;

import static com.linecorp.armeria.common.HttpStatus.OK;

ServerBuilder sb = Server.builder();
Server server = sb.http(port)
                  .route()
                  .get("/hello1")
                  .requestTimeout(Duration.ofSeconds(5))
                  .build((ctx, req) -> HttpResponse.of(OK))
                  .withRoute(builder -> {
                      builder.get("/hello2")
                             .requestTimeout(Duration.ofSeconds(10))
                             .build((ctx, req) -> HttpResponse.of(OK));
                  })
                  .build();
`}</code></pre>
    <h2 {...{
      "id": "using-typeservicerequestcontext",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-typeservicerequestcontext",
        "aria-label": "using typeservicerequestcontext permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<a parentName="h2" {...{
        "href": "type://ServiceRequestContext:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServiceRequestContext.html"
      }}>{`type://ServiceRequestContext`}</a></h2>
    <p>{`You can use the context to set the timeouts for a request.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.ServerBuilder;

import java.time.Duration;

import static com.linecorp.armeria.common.HttpStatus.OK;

ServerBuilder sb = Server.builder();
Server server = sb.http(port)
                  .service("/hello", (ctx, req) -> {
                      ctx.setRequestTimeout(Duration.ofSeconds(5));
                      return HttpResponse.of(OK);
                  })
                  .build();
`}</code></pre>
    <h2 {...{
      "id": "using-typerequesttimeout-annotation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#using-typerequesttimeout-annotation",
        "aria-label": "using typerequesttimeout annotation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<a parentName="h2" {...{
        "href": "type://@RequestTimeout:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/decorator/RequestTimeout.html"
      }}>{`type://@RequestTimeout`}</a>{` Annotation`}</h2>
    <p>{`You can use the `}<a parentName="p" {...{
        "href": "type://@RequestTimeout:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/annotation/decorator/RequestTimeout.html"
      }}>{`type://@RequestTimeout`}</a>{` annotation for annotated services and gRPC services.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=HelloService.java",
        "filename": "HelloService.java"
      }}>{`import com.linecorp.armeria.common.HttpResponse;
import com.linecorp.armeria.server.ServiceRequestContext;
import com.linecorp.armeria.server.annotation.Get;
import com.linecorp.armeria.server.annotation.decorator.RequestTimeout;

import java.time.Duration;
import java.util.concurrent.TimeUnit;

import static com.linecorp.armeria.common.HttpStatus.OK;

@RequestTimeout(value = 10, unit = TimeUnit.SECONDS)
public class HelloService {

    @Get("/hello")
    @RequestTimeout(5000)
    public HttpResponse getHello() {
        return HttpResponse.of(OK);
    }

}
`}</code></pre>
    <h2 {...{
      "id": "global-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#global-configuration",
        "aria-label": "global configuration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Global Configuration`}</h2>
    <p>{`You may globally configure the default timeout with system properties or a custom `}<a parentName="p" {...{
        "href": "type://FlagsProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html"
      }}>{`type://FlagsProvider`}</a>{`.`}</p>
    <h3 {...{
      "id": "using-typeflagsprovider",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-typeflagsprovider",
        "aria-label": "using typeflagsprovider permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using `}<a parentName="h3" {...{
        "href": "type://FlagsProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html"
      }}>{`type://FlagsProvider`}</a></h3>
    <p>{`You can override the request timeout specified by JVM system properties by implementing your own `}<a parentName="p" {...{
        "href": "type://FlagsProvider:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/FlagsProvider.html"
      }}>{`type://FlagsProvider`}</a>{` and loading it via Java SPI.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`package com.example.providers;

// Add the following text file to your classpath or JAR file:
//
// $ cat META-INF/services/com.linecorp.armeria.common.FlagsProvider
// com.example.providers.MyFlagsProvider
//
public class MyFlagsProvider implements FlagsProvider {
  @Override
  public int priority() {
    // The provider with higher value will be evaluated first.
    return 100;
  }

  @Override
  public Long defaultRequestTimeoutMillis() {
      return 1000L;
  }
}
`}</code></pre>
    <h3 {...{
      "id": "using-jvm-system-properties",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#using-jvm-system-properties",
        "aria-label": "using jvm system properties permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using JVM system properties`}</h3>
    <p>{`You can override the default server timeouts by specifying the following JVM system properties if you do not prefer setting it programmatically.`}</p>
    <p><inlineCode parentName="p">{`-Dcom.linecorp.armeria.defaultRequestTimeoutMillis=<long>`}</inlineCode></p>
    <p>{`Represents the amount of time from receiving a request to sending the corresponding response completely. Default: `}<inlineCode parentName="p">{`10000`}</inlineCode>{`.`}</p>
    <Warning mdxType="Warning">
      <p>{`  The JVM system properties have effect only when you did not specify them programmatically.
See `}<a parentName="p" {...{
          "href": "type://Flags:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/Flags.html"
        }}>{`type://Flags`}</a>{` for the complete list of JVM system properties in Armeria.`}</p>
    </Warning>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      